import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import '../style/Profile.css'; // Import BuyCar.css  
import BiddingComponents from '../components/InnerComponents/BiddingComponents';
import BidReviewStatus from '../components/InnerComponents/BidReviewStatus';
import BidRejectedStatus from '../components/InnerComponents/BidRejectedStatus';
import BidSuccessfulStatus from '../components/InnerComponents/BidSuccessfulStatus';
import ListedCars from '../components/InnerComponents/ListedCars';
import PurchasedCarsComponent from '../components/InnerComponents/PurchasedCarsComponent';
import ProfileSetting from '../components/InnerComponents/ProfileSetting';
import defaultCarImage from "../images/cars/carValuation.png";
import defaultprofile from "../images/user.jpg";
function Profile() {
    const [cars, setCars] = useState([]);
    const [bids, setBids] = useState([]);
    const [userInfo, setUserInfo] = useState({});

    useEffect(() => {
        const userInfo = JSON.parse(localStorage.getItem("user_detail"));
        setUserInfo(userInfo || {});
        fetchListedCars(userInfo?.id);
        fetchCarBids(userInfo?.id);
    }, []);

    const fetchListedCars = async (userId) => {
        if (!userId) return;
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/car/fetch-carbyuser/${userId}`);
            const data = await response.json();
            setCars(data.allCars);
        } catch (error) {
            console.error("Error fetching cars:", error);
        }
    };

    const fetchCarBids = async (userId) => {
        if (!userId) return;
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/offer/fetch-bidsbyuser/${userId}`);
            const data = await response.json();
            setBids(data.bidding);
        } catch (error) {
            console.error("Error fetching bidding:", error);
        }
    };

    const handleLogout = () => {
        localStorage.clear(); // Clear all local storage items
        window.location.href = "/";
    };

    return (
        <div>
            <section className="ProfileTopBanner m-0"></section>
            <section className="UserProfileData">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card bg-none border-none">
                                <figure className="m-0">
                                    <img src={defaultprofile} alt="" />
                                </figure>
                                <div>
                                    <h1>{userInfo.name}</h1>
                                    <Link to="/"><p><ion-icon name="call-outline"></ion-icon> +91 {userInfo.phone}</p></Link>
                                    {userInfo.email && (
                                        <Link to="/">
                                            <p>
                                                <ion-icon name="mail-unread-outline"></ion-icon> {userInfo.email}
                                            </p>
                                        </Link>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="MainProfileContainer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 ProfileLeftSide">
                            <div className="card border-none">
                                <div className="UserAllData">
                                    <ul className="nav nav-tabs tabs-left sideways">
                                        {["Bidding", "ListedCars", "PurchasedCars", "ProfileSetting"].map((tab) => (
                                            <li key={tab} className={tab === "Bidding" ? "active" : ""}>
                                                <a href={`#${tab}`} data-toggle="tab">
                                                    {tab} <ion-icon name="chevron-forward-outline"></ion-icon>
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                    <button onClick={handleLogout}>
                                        <ion-icon name="log-out-outline"></ion-icon> LOGOUT
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8 ProfileRightSide">
                            <div className="card bg-none border-none">
                                <div className="tab-content">
                                    <div className="tab-pane active" id="Bidding">
                                        <div className="row">
                                            <div className="head">
                                                <h5>Total Bids <span>({bids.length})</span> : Discover Real-time Insights and Trends in Car Auctions.</h5>
                                            </div>
                                            <div className="defaultListedItems">
                                                {bids.map((bid, index) => (
                                                    <BiddingComponents
                                                        key={index}
                                                        biddingCarImage={bid.Car.front_view ? `https://usedcarautoscan.s3.ap-south-1.amazonaws.com/${bid.Car.front_view}` : defaultCarImage}
                                                        CarName={`${bid.Car.brand.brand_name} ${bid.Car.model.model_name} ${bid.Car.varient.varient_name}`}
                                                        CarPriceFrom={`₹${bid.orginal_price.toLocaleString()}`}
                                                        CarPriceTo={`₹${bid.bid_price.toLocaleString()} Lakh`}
                                                        CarBid="Your Bid/Offer"
                                                        CarDemandPrice={`₹${bid.bid_price.toLocaleString()} Lakh`}
                                                    >
                                                        {bid.status === 0 ? (
                                                            <BidReviewStatus statusTitle="Bid in Review" />
                                                        ) : bid.status === 1 ? (
                                                            <BidSuccessfulStatus statusTitle="Bid Successful" />
                                                        ) : (
                                                            <BidRejectedStatus statusTitle="Bid Rejected" />
                                                        )}
                                                    </BiddingComponents>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane" id="ListedCars">
                                        <div className="row">
                                            <div className="head">
                                                <h5>Total Listed Cars <span>({cars.length})</span> : Explore the Thriving Marketplace of Auctioned Vehicles in Car Auctions.</h5>
                                            </div>
                                            <div className="listingDetails defaultListedItems">
                                                {cars.map((car) => (
                                                    <ListedCars
                                                        key={car.id}
                                                        ListedCarsImg={car.front_view ? `https://usedcarautoscan.s3.ap-south-1.amazonaws.com/${car.front_view}` : defaultCarImage}
                                                        ListedCarName={`${car.brand.brand_name} ${car.model.model_name} ${car.varient.varient_name}`}
                                                        ListedCarPriceFrom={`₹${car.price}`}
                                                        ListedCarOn={`You have ${car.kms_driven} km driven`}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane" id="PurchasedCars">
                                        <div className="head">
                                            <h5>Total Purchased Cars <span>(02)</span> : Total Cars Acquired in Auctions Revealed in Car Auctions.</h5>
                                        </div>
                                        <div className="PurchasedDetails defaultListedItems">
                                            {[...Array(2)].map((_, index) => (
                                                <PurchasedCarsComponent
                                                    key={index}
                                                    PurchasedCarsImg={require('../images/cars/trending2.png')}
                                                    PurchasedCarName="Hyundai Creta"
                                                    PurchasedCarPriceFrom="₹6.37"
                                                    PurchasedCarPriceTo="₹7.54 Lakh"
                                                    PurchasedCarBid="Your Bid/Offer"
                                                    PurchasedCarDemandPrice="₹6 lakh"
                                                    PurchasedOn={`Approved on ${index === 0 ? "9-May-2024" : "23-May-2024"}`}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                    <div className="tab-pane" id="ProfileSetting">
                                        <div className="row ProfileSettingForm">
                                            <ProfileSetting />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Profile;
