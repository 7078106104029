import React from "react";
import "../../style/DealerContactModal.css";

function ContactModal({ onClose, title, name, phone }){
  

  return (
<div className="modal">
      <div className="modal-content">
        <span className="close" onClick={onClose}>
          &times;
        </span>
        <h5>{title}</h5>
        <div className="row d-flex mt-3">
          <div className="col-md-6">
          <strong>Name:</strong> {name}
          </div>
          <div className="col-md-6">
          <strong>Phone:</strong> {phone}
          </div>
        </div>
      </div>
    </div>
      );
    }
    

 export default ContactModal;
