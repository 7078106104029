import React, { useState } from "react";
// import faqImg from '../images/main/faq'
function FaqAccordion() {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <section className="specificationDiv faq">
            <div className="container">
                <div className="row">
                    <div className="col-md-8">
                        <div className="card border-none">
                            <h2 className="l-title">FAQ's</h2>
                            <div className="accordion">
                                <div className="heading" onClick={() => toggleAccordion(0)}>
                                How do I list my used car for sale on Used Car Wale?
                                </div>
                                <div className={`contents ${activeIndex === 0 ? 'active' : ''}`}>
                                Listing your car is easy! Just click on "Sell Your Car" on our homepage, fill in the necessary details about your vehicle, upload photos, and submit your listing. Your ad will be live after a quick review.
                                </div>

                                <div className="heading" onClick={() => toggleAccordion(1)}>
                                Is there a fee to list my car on Used Car Wale?
                                </div>
                                <div className={`contents ${activeIndex === 1 ? 'active' : ''}`}>
                                We offer both free and paid listing options for individual sellers. Free listings have a limited duration, while paid listings can enhance visibility and stay live for longer periods.
                                </div>

                                <div className="heading" onClick={() => toggleAccordion(2)}>
                                What information do I need to provide to list my car?
                                </div>
                                <div className={`contents ${activeIndex === 2 ? 'active' : ''}`}>
                                You will need to provide the make, model, year, mileage, condition, and other relevant details of your vehicle, along with high-quality photos.
                                </div>

                                <div className="heading" onClick={() => toggleAccordion(3)}>
                                How long will my listing stay active?
                                </div>
                                <div className={`contents ${activeIndex === 3 ? 'active' : ''}`}>
                                Free listings are active for 7 days. You can renew your listing before it expires or upgrade to a paid listing for extended visibility.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 desktop-view">
                        <div class="card bg-none border-none">
                            {/* <figure><img src={faqImg} alt="" class="W100" /></figure> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default FaqAccordion;
