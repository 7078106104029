import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../style/ExploreCarDetails.css";
import RecentSearch from "../components/RecentSearch";
import { Swiper, SwiperSlide } from "swiper/react";
import SpecificationAccordion from "../components/SpecificationAccordian";
import FaqAccordion from "../components/FaqAccordion.jsx";
import MakeOffer from "../components/modals/MakeOffer";
import LoginModal from "../components/modals/LoginModal";
import ContactModal from "../components/modals/ContactModal"
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
// import required modules
import { FreeMode, Navigation, Thumbs } from "swiper/modules";

function CarDetails() {
  const [showContactModal, setShowContactModal] = useState(false);
  const [contactInfo, setContactInfo] = useState({});
  
  const [showMakeOffer, setShowOfferModal] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [car, setCar] = useState({});
  let { carId } = useParams();
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const formatPrice = (price) => {
    if (price === undefined || price === null) {
      return "Price not available"; // Return a default message
    }

    if (price >= 100000) {
      return `Rs. ${(price / 100000).toFixed(2)} Lakh`;
    }

    return `Rs. ${price.toLocaleString()}`;
  };

  const updateLoginState = (state) => {
    setIsLoggedIn(state);
  };

  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };

  // Function to convert HTML to plain text
  const stripHtml = (html) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = html;
    return tempElement.textContent || tempElement.innerText || "";
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/car/fetch-Car/${carId}`
        );
        const data = await response.json();
        setCar(data.car);
      } catch (error) {
        console.error("Error fetching cars:", error);
      }
    };

    fetchData();
  }, [carId]); // added carId to the dependency array

  return (
    <div>
      <section className="ActivePageHeader">
        <div className="container">
          <div className="row">
            <div className="d-flex align-items-center">
              <a href={"/"} className="active">
                Home
              </a>
              <span>
                <ion-icon name="chevron-forward-outline"></ion-icon>
              </span>
              <a href={"/explore-car"} className="active">
                {" "}
                Used Cars
              </a>
              <span>
                <ion-icon name="chevron-forward-outline"></ion-icon>
              </span>
              <span>
                {car.brand ? car.brand.brand_name : ""}{" "}
                {car.model ? car.model.model_name : ""}{" "}
              </span>
            </div>
          </div>
        </div>
      </section>
      <section className="singleCarMain">
        <div className="container">
          <div className="row">
          <div className="col-md-8 leftSide carAllDetails">
  <div className="card border-none bg-none">
    <Swiper
      style={{
        "--swiper-navigation-color": "#fff",
        "--swiper-pagination-color": "#fff",
      }}
      spaceBetween={10}
      navigation={true}
      thumbs={{ swiper: thumbsSwiper }}
      modules={[FreeMode, Navigation, Thumbs]}
      className="mySwiper2"
    >
      {car.front_view && (
        <SwiperSlide>
          <img
            src={`https://usedcarautoscan.s3.ap-south-1.amazonaws.com/${car.front_view}`}
          />
        </SwiperSlide>
      )}
      {car.front_left && (
        <SwiperSlide>
          <img
            src={`https://usedcarautoscan.s3.ap-south-1.amazonaws.com/${car.front_left}`}
          />
        </SwiperSlide>
      )}
      {car.front_right && (
        <SwiperSlide>
          <img
            src={`https://usedcarautoscan.s3.ap-south-1.amazonaws.com/${car.front_right}`}
          />
        </SwiperSlide>
      )}
      {car.left_view && (
        <SwiperSlide>
          <img
            src={`https://usedcarautoscan.s3.ap-south-1.amazonaws.com/${car.left_view}`}
          />
        </SwiperSlide>
      )}
      {car.right_view && (
        <SwiperSlide>
          <img
            src={`https://usedcarautoscan.s3.ap-south-1.amazonaws.com/${car.right_view}`}
          />
        </SwiperSlide>
      )}
      {car.rear_view && (
        <SwiperSlide>
          <img
            src={`https://usedcarautoscan.s3.ap-south-1.amazonaws.com/${car.rear_view}`}
          />
        </SwiperSlide>
      )}
      {car.rear_left && (
        <SwiperSlide>
          <img
            src={`https://usedcarautoscan.s3.ap-south-1.amazonaws.com/${car.rear_left}`}
          />
        </SwiperSlide>
      )}
      {car.rear_right && (
        <SwiperSlide>
          <img
            src={`https://usedcarautoscan.s3.ap-south-1.amazonaws.com/${car.rear_right}`}
          />
        </SwiperSlide>
      )}
      {car.odometer && (
        <SwiperSlide>
          <img
            src={`https://usedcarautoscan.s3.ap-south-1.amazonaws.com/${car.odometer}`}
          />
        </SwiperSlide>
      )}
      {car.engine && (
        <SwiperSlide>
          <img
            src={`https://usedcarautoscan.s3.ap-south-1.amazonaws.com/${car.engine}`}
          />
        </SwiperSlide>
      )}
      {car.chessis && (
        <SwiperSlide>
          <img
            src={`https://usedcarautoscan.s3.ap-south-1.amazonaws.com/${car.chessis}`}
          />
        </SwiperSlide>
      )}
      {car.interior && (
        <SwiperSlide>
          <img
            src={`https://usedcarautoscan.s3.ap-south-1.amazonaws.com/${car.interior}`}
          />
        </SwiperSlide>
      )}
    </Swiper>
    <Swiper
      onSwiper={setThumbsSwiper}
      spaceBetween={10}
      slidesPerView={4}
      freeMode={true}
      watchSlidesProgress={true}
      modules={[FreeMode, Navigation, Thumbs]}
      className="mySwiper"
    >
      {car.front_view && (
        <SwiperSlide>
          <img
            src={`https://usedcarautoscan.s3.ap-south-1.amazonaws.com/${car.front_view}`}
          />
        </SwiperSlide>
      )}
      {car.front_left && (
        <SwiperSlide>
          <img
            src={`https://usedcarautoscan.s3.ap-south-1.amazonaws.com/${car.front_left}`}
          />
        </SwiperSlide>
      )}
      {car.front_right && (
        <SwiperSlide>
          <img
            src={`https://usedcarautoscan.s3.ap-south-1.amazonaws.com/${car.front_right}`}
          />
        </SwiperSlide>
      )}
      {car.left_view && (
        <SwiperSlide>
          <img
            src={`https://usedcarautoscan.s3.ap-south-1.amazonaws.com/${car.left_view}`}
          />
        </SwiperSlide>
      )}
      {car.right_view && (
        <SwiperSlide>
          <img
            src={`https://usedcarautoscan.s3.ap-south-1.amazonaws.com/${car.right_view}`}
          />
        </SwiperSlide>
      )}
      {car.rear_view && (
        <SwiperSlide>
          <img
            src={`https://usedcarautoscan.s3.ap-south-1.amazonaws.com/${car.rear_view}`}
          />
        </SwiperSlide>
      )}
      {car.rear_left && (
        <SwiperSlide>
          <img
            src={`https://usedcarautoscan.s3.ap-south-1.amazonaws.com/${car.rear_left}`}
          />
        </SwiperSlide>
      )}
      {car.rear_right && (
        <SwiperSlide>
          <img
            src={`https://usedcarautoscan.s3.ap-south-1.amazonaws.com/${car.rear_right}`}
          />
        </SwiperSlide>
      )}
      {car.odometer && (
        <SwiperSlide>
          <img
            src={`https://usedcarautoscan.s3.ap-south-1.amazonaws.com/${car.odometer}`}
          />
        </SwiperSlide>
      )}
      {car.engine && (
        <SwiperSlide>
          <img
            src={`https://usedcarautoscan.s3.ap-south-1.amazonaws.com/${car.engine}`}
          />
        </SwiperSlide>
      )}
      {car.chessis && (
        <SwiperSlide>
          <img
            src={`https://usedcarautoscan.s3.ap-south-1.amazonaws.com/${car.chessis}`}
          />
        </SwiperSlide>
      )}
      {car.interior && (
        <SwiperSlide>
          <img
            src={`https://usedcarautoscan.s3.ap-south-1.amazonaws.com/${car.interior}`}
          />
        </SwiperSlide>
      )}
    </Swiper>
  </div>
</div>

            <div className="col-md-4 rightSide carhighlightsDetails">
              <div className="card bg-none border-none">
                <div>
                  {car && (
                    <h5 className="card-title">
                      {car.brand ? car.brand.brand_name : ""}{" "}
                      {car.model ? car.model.model_name : ""}{" "}
                      {car.varient ? car.varient.varient_name : ""}
                    </h5>
                  )}
                  <ul className="p-0">
                    <li>
                      Year
                      <span className="year"> : {car.manufacturing_year}</span>
                    </li>
                    <li>
                      Location
                      <span className="Location">
                        {" "}
                        : {car.current_location}
                      </span>
                    </li>
                    <li>
                      KM Driven
                      <span className="KMDriven"> : {car.kms_driven}</span>
                    </li>
                  </ul>
                  <div id="desc">
                    <div className="article">
                      {/* Render the HTML description */}
                      <p
                        className="s-para"
                        dangerouslySetInnerHTML={{
                          __html: car.car_description,
                        }}
                      />
                      {/* Display the plain text description */}
                      {/* <p className="plain-text s-para">
                        {stripHtml(car.car_description)}
                      </p> */}
                      {/* <p
                        className="moretext s-para"
                        style={{ display: isExpanded ? "block" : "none" }}
                      >
                       <p className="plain-text s-para">
                        {stripHtml(car.car_description)}
                      </p>
                      </p> */}
                    </div>
                    {/* <div>
                      <span className="moreless-button" onClick={toggleText}>
                        {isExpanded ? "Read less" : "Read more"}
                      </span>
                    </div> */}
                  </div>
                  <p className="priceFrom">
                    <span> {formatPrice(car.price)}</span>
                  </p>
                </div>
                <div>
                  {/* <div className="ribbon-2">
                    Wohoo!! Congratulations <b>30%</b> OFF
                  </div> */}
                  <div className="grid">
                    {localStorage.getItem("authUser") ? (
                      <button
                        onClick={() => setShowOfferModal(true)}
                        className="MakeOfferModal bg-none border-none"
                      >
                        Make Offer
                      </button>
                    ) : (
                      <button
                        onClick={() => setShowUserModal(true)}
                        className="MakeOfferModal bg-none border-none"
                      >
                        Make Offer
                      </button>
                    )}
                  </div>
                  <button
  onClick={() => {
    setShowContactModal(true);
    setContactInfo(
      car.dealer
        ? { title: "Dealer Contact Details", name: car.dealer?.name, phone: car.dealer?.phone }
        : { title: "UsedCarWale Contact Details", name: "UsedCarWale Support", phone: "123-456-7890" }
    );
  }}
  className="ViewContactModal bg-none border-none"
>
  {car.dealer ? "View Dealer Contact" : "View UsedCarWale Contact Details"}
</button>

                  <div className="applyForLoanDiv">
                    <div>
                      <figure>
                        <img
                          src="images/main/LoanBAnner.png"
                          alt=""
                          className="W100"
                        />
                      </figure>
                    </div>
                    <div>
                      <div className="content">
                        <h2>Check your eligibility & apply online</h2>

                        <p className="s-para">
                          {" "}
                          Get instant approval. Become a Car owner today!
                        </p>
                      </div>
                      <a target="_blank" href="https://web.whatsapp.com/">
                        <div className="button">Apply For a Loan</div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {showMakeOffer && (
          <MakeOffer
            onClose={() => setShowOfferModal(false)}
            price={car.price}
            car_id={car.id}
          />
        )}
        <div>
          {showUserModal && (
            <LoginModal
              onClose={() => setShowUserModal(false)}
              updateLoginState={updateLoginState}
            />
          )}
        </div>
        <div>
        {showContactModal && (
  <ContactModal
    onClose={() => setShowContactModal(false)}
    title={contactInfo.title}
    name={contactInfo.name}
    phone={contactInfo.phone}
  />
)}

        </div>
      </section>

      <section className="faqAccordion">
        <div className="container">
          <FaqAccordion />
        </div>
      </section>
      {/* 
      <section className="specificationAccordion">
        <div className="container">
          <SpecificationAccordion />
        </div>
      </section> */}
      <section className="recentSearch">
        <RecentSearch />
      </section>
    </div>
  );
}

export default CarDetails;
